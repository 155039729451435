"use client";

import { Box, Button, Image, Link, Stack, Typography } from "components";
import { parseToReactElement, withContent } from "contexts/content";
import classnames from "classnames";
import { useCallback } from "react";
import { currency } from "utils";
import Picture from "./Picture";

function HomePricing({ config, redirect, screen, website }) {
  const handleClick = useCallback(
    (plan) =>
      redirect.register({
        params: { plan },
      }),
    [redirect]
  );

  return (
    <>
      <Box id="home-pricing" className="BupHome-pricing" component="section">
        <Typography variant="h2">
          {website.get("home.pricing.title")}
        </Typography>
        <Box component="main">
          {config.plans.slice(0, 2)?.map((plan, index) => (
            <PlanCard
              key={index}
              handleClick={handleClick}
              local={website.get(`home.pricing.plans.${index}`)}
              {...{ plan, screen, website }}
            />
          ))}
        </Box>
        <br />
        <br />
        <br />
        <Stack spacing={1}>
          <Typography className="Bup-disclaimer" component="p">
            ¿Te interesa también tu salud y bienestar? Tenemos planes para tí
          </Typography>
          <Box component="center">
            <Typography variant="h5">
              Elige tu plan de entretenimiento, salud y bienestar
            </Typography>
          </Box>
        </Stack>
        <Box component="main" className="BupHome-pricingHealth">
          {config.plans.slice(2, 4)?.map((plan, index) => (
            <PlanCard
              key={index}
              handleClick={handleClick}
              local={website.get(`home.pricing.plans.${index + 2}`)}
              {...{ plan, screen, website }}
            />
          ))}
        </Box>
        <Typography className="Bup-disclaimer" component="p">
          {website.get("home.pricing.disclaimer")}
        </Typography>
      </Box>
      <Picture {...{ screen, website }} />
    </>
  );
}

const PlanCard = ({ handleClick, local, plan, screen, website }) => (
  <Box
    className={classnames("BupSelector-card", {
      "min-xl": plan.price === 0 && !screen.mobile,
    })}
  >
    <Box
      className={classnames("BupSelector-plan", {
        "BupSelector-popular": plan.popular,
      })}
    >
      <Box className="BupSelector-filter">
        {plan.popular && (
          <Box className="Bup-popular">
            {website.get("home.pricing.popularLabel")}
          </Box>
        )}
        <Stack alignItems="center" spacing={1.5}>
          <Typography variant="h3">{local.subtitle}</Typography>
          <Image alt="plan-basic" src="utils/l0.png" height={1} width={300} />
          <Typography variant="h4">CLP {currency(plan.price)}/mes*</Typography>
          <Typography variant="p">
            {parseToReactElement(local.features)}
          </Typography>
        </Stack>
        <Stack alignItems="center" spacing={1.5}>
          <Button
            id={`BH-pricing-${plan.name}`}
            color="secondary"
            className="BupButton-dark"
            onClick={() => handleClick(plan.name)}
            size="large"
          >
            {website.get("home.pricing.buttonText")}
          </Button>
          {plan.price !== 0 && (
            <Link to="/precios#pricing-compare">
              {website.get("home.pricing.detailsLink")}
            </Link>
          )}
        </Stack>
      </Box>
    </Box>
  </Box>
);

export default withContent(HomePricing);
