"use client";

import { Box, Button, Image, Fragment, Stack, Typography } from "components";

function HomePicture({ screen, website }) {
  const Component = screen?.mobile ? Fragment : Image;

  return (
    <Box id="home-picture" className="BupHome-picture" component="section">
      <Component alt="pricing-top" src="ia/explore-4.png">
        <Stack className="BupImage-filter" spacing={1.5}>
          <Typography variant="h4">
            {website.get("home.picture.question")}
          </Typography>
          <Typography variant="p">
            {website.get("home.picture.invitation")}
          </Typography>
          <Button
            id="BH-picture"
            className="BupButton-wide"
            color="inherit"
            variant="outlined"
            size="large"
          >
            {website.get("home.picture.buttonText")}
          </Button>
        </Stack>
      </Component>
      {screen?.mobile && (
        <Image
          alt="pricing-cta"
          src="ia/explore-4.png"
          height={320}
          width={180}
        />
      )}
    </Box>
  );
}

export default HomePicture;
