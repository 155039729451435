"use client";

import {
  Box,
  Button,
  Divider,
  Icon,
  Image,
  Stack,
  Typography,
} from "components";
import { withContent } from "contexts/content";
import { useRouter } from "next/navigation";
import Features from "./Features";

function HomeDiscover({ screen, website }) {
  const router = useRouter();

  return (
    <Box id="home-discover" className="BupHome-discover" component="section">
      <Typography variant="h2">{website.get("home.discover.title")}</Typography>
      <Box component="main">
        <Box className="BupDiscover-service">
          <Typography variant="h3">
            {website.get("home.discover.subtitle")}
          </Typography>
          <Stack spacing={0.75}>
            <Typography className="BupDiscover-points" component="span">
              {website.get("home.discover.pointsLabel.0")}
            </Typography>
            <Typography
              className="BupDiscover-points BupPoints-events"
              component="span"
            >
              {website.get("home.discover.pointsLabel.1")}
            </Typography>
          </Stack>
          {website.get("home.discover.paragraphs")?.map((p, i) => (
            <Typography key={i} variant="p">
              {p}
            </Typography>
          ))}
          <Box component="ul" hidden>
            {website.get("home.discover.bulletPoints")?.map((habit, index) => (
              <Box key={index} component="li">
                <Image
                  alt={`habits-${index + 1}`}
                  //src={`assets/discover-${index + 1}.webp`}
                  height={120}
                  width={120}
                />
                <Typography variant="p">{habit}</Typography>
              </Box>
            ))}
          </Box>
        </Box>
        <Box className="BupDiscover-image">
          <Image
            alt="playing-guitar"
            src="ia/explore-3.png"
            height={270}
            width={480}
          />
          <Typography className="Bup-disclaimer" component="p">
            {website.get("home.discover.disclaimer")}
          </Typography>
        </Box>
      </Box>
      {/*<Features {...{ screen, website }} />*/}
      <br />
      <br />
      <br />
      <Button
        id="BH-events"
        className="BupButton-wide"
        color="inherit"
        onClick={() => router.push("/panoramas")}
        size="large"
        variant="text"
      >
        {website.get("home.discover.buttonText")}
      </Button>
      <Divider />
    </Box>
  );
}

export default withContent(HomeDiscover);
