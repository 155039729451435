"use client";

import { Box, Button, Divider, Image, Stack, Typography } from "components";
import { withContent } from "contexts/content";
import { useRouter } from "next/navigation";
import Video from "../banco/Video";

function HomeDiscover({ redirect, screen, website }) {
  const router = useRouter();

  return (
    <Box id="home-banco" className="BupHome-banco" component="section">
      <Box className="BupPartner-logo">
        <Image
          alt="Logo Banco de Chile"
          src="logos/banco.jpg"
          width={300}
          height={100}
        />
      </Box>
      <Stack className="BupPartner-banner" spacing={8}>
        <Typography variant="h2">
          Si eres cliente del Banco de Chile, ¡Tenemos beneficios exclusivos
          para ti!
        </Typography>
        <center>
          <Button
            id="BH-banco"
            className="BupButton-wide"
            color="inherit"
            onClick={() => router.push("/bancochile")}
            size="large"
            variant="text"
          >
            Ver beneficios
          </Button>
        </center>
      </Stack>
      <Video />
      <Divider />
    </Box>
  );
}

export default withContent(HomeDiscover);
